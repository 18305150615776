<template>
  <div ref="editor" v-html="editorData"></div>
</template>

<script>
import axios from 'axios'
import Editor from '@postodian/ckeditor5'

class UploadAdapter {
  constructor(loader, data) {
    this.article = data.article
    this.loader = loader
    this.publication = data.publication
  }

  upload() {
    return this.loader.file
    .then(file => new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('file', file)
      this.send(data, resolve, reject)
    }))
  }

  abort() {
    let cancelUpload = this.cancelUpload
    cancelUpload()
  }

  send(data, resolve, reject) {
    const CancelToken = axios.CancelToken
    let cancelUpload = this.cancelUpload

    axios.post(`${ process.env.VUE_APP_BASE_URL }/publications/${ this.publication }/articles/${ this.article }/images`, data, {
      onUploadProgress: progressEvent => {
        this.loader.uploadTotal = progressEvent.total
        this.loader.uploaded = progressEvent.loaded
      },
      cancelToken: new CancelToken(function executor(c) {
        cancelUpload = c
      }),
      withCredentials: true,
    })
    .then(res => {
      setTimeout(() => {
        resolve(res.data.resolutions)
      }, 1000)
    })
    .catch(err => {
      if (err.response && err.response.status === 422) {
        return reject(err.response.data.message)
      } else {
        return reject(err.message)
      }
    })
  }
}

export default {
  props: {
    modelValue: String,
  },

  data() {
    return {
      editorData: this.modelValue,
      editorConfig: {
        blockToolbar: [
          'heading',
          '|',
          'bulletedList',
          'numberedList',
          '|',
					'imageUpload',
					'mediaEmbed',
          'blockQuote',
					'codeBlock',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
				image: {
					toolbar: [
						'imageTextAlternative',
            '|',
						'imageStyle:block',
						'imageStyle:side',
            '|',
            'toggleImageCaption',
						'linkImage',
					]
				},
        language: this.$store.state.article.data.language,
        mediaEmbed: {
          previewsInData: true,
        },
				table: {
					contentToolbar: [
						'tableColumn',
						'tableRow',
						'mergeTableCells',
            '|',
            'toggleTableCaption',
					]
				},
				toolbar: {
					items: [
						'bold',
						'italic',
						'underline',
						'strikethrough',
            'code',
						'subscript',
						'superscript',
            '|',
						'link',
						'highlight',
            '|',
            'removeFormat',
					],
				},
      },
    }
  },

  async mounted() {
    try {
      var editor = await Editor.create(this.$refs.editor, this.editorConfig)
    } catch (err) {
      console.error(err)
    }

    editor.model.document.on('change:data', (evt, data) => {
      this.$emit('update:modelValue', editor.getData())
    })
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, {
        article: this.$store.state.article.data.id,
        publication: this.$store.state.publication.data.id,
      })
    }
  },
}
</script>

<style>
.ck.ck-editor__editable_inline {
  border: none !important;
  font-size: 16px;
  padding: 0 !important;
}

@media (max-width: 576px) {
  .ck.ck-editor__editable_inline {
    font-size: 14px;
  }
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.ck-content .table table {
  table-layout: fixed;
}
</style>
