<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Articles <small v-if="articlesCount !== null" class="text-muted">({{ articlesCount }})</small>
    </h1>
    <ArticleCreate
      :visible="visible"
      @cancel="visible = false"
      @success="onSuccess"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </ArticleCreate>
  </div>
  <BaseSpinner :loading="loading">
    <ArticleItem
      v-for="(article, i) in articles"
      :article="article"
      class="py-3"
      :class="{ 'border-bottom': i < articlesCount - 1 }"
      @update="listArticles"
    />
    <BaseEmpty v-if="!articlesCount">
      Create the first article for your publication.
    </BaseEmpty>
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(articlesCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import ArticleCreate from '@/components/Article/ArticleCreate'
import ArticleItem from '@/components/Article/ArticleItem'
import BaseEmpty from '@/components/Base/BaseEmpty'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    ArticleCreate,
    ArticleItem,
    BaseEmpty,
    BasePagination,
    BaseSpinner,
    PlusIcon,
  },

  data() {
    return {
      articles: [],
      articlesCount: null,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      hasMore: false,
      loading: false,
      visible: false,
    }
  },

  async mounted() {
    await this.listArticles()
  },

  methods: {
    getDateFormatted(date) {
      return moment(date).format('LL')
    },
    async listArticles() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/articles`, {
          params: this.filters,
        })

        this.articles = data.articles
        this.articlesCount = data.articlesCount
        this.hasMore = data.hasMore
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
    onSuccess(data) {
      this.visible = false
      this.$router.push({ name: 'publications-id-articles-id', params: { article: data.id }})
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'publications-id-articles', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
