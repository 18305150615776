<template>
  <div>
    <p
      id="description"
      class="lead mb-0"
      v-text="modelValue"
      v-once
      contenteditable="plaintext-only"
      data-placeholder="Describe your article’s content"
      data-maxlength="250"
      @input="e => $emit('update:modelValue', e.target.innerText)"
    />
    <label
      class="counter"
      :class="{
        'text-danger': counter < 0,
        'text-muted': counter >= 160,
        'text-warning': counter < 90,
      }"
    >
      {{ counter }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
  },

  computed: {
    counter() {
      return this.modelValue ? 250 - this.modelValue.length : 250
    },
  },
}
</script>
