<template>
  <slot />
</template>

<script>
export default {
  props: {
    article: String,
    visible: Boolean,
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/articles/${ this.article }`)

        this.$message.success('Article has been deleted.')
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.$emit('cancel')
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value === true) {
        if (this.canDelete) {
          this.$confirm({
            title: 'Delete Article',
            content: 'This article will be deleted from your publication immediately. The action cannot be undone.',
            confirmLoading: this.loading,
            okText: 'Delete',
            okType: 'danger',
            onOk: async () => {
              await this.onConfirm()
            },
            onCancel: () => {
              this.$emit('cancel')
            },
          })
        } else {
          this.$error({
            title: 'Cannot Delete Article',
            content: 'Contributors cannot delete anyone’s article. Please ask an authorized user to delete an article.',
            onOk: async () => {
              this.$emit('cancel')
            },
          })
        }
      } else {
        this.$emit('cancel')
      }
    },
  },

  computed: {
    canDelete() {
      return this.$store.getters['publication/isAdmin'] || this.$store.getters['publication/permissions'].indexOf('article.delete') >= 0
    },
  },
}
</script>
