<template>
  <slot />
  <a-modal
    title="New Article"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Confirm"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Title"
        name="title"
        :rules="[
          { required: true, message: 'Title is required.' },
        ]"
      >
        <a-input
          v-model:value="values.title"
          :maxlength="100"
          name="title"
        />
      </a-form-item>
      <a-form-item label="Section" name="section">
        <a-select
          v-model:value="values.section"
          name="section"
          :loading="loadingSections"
          placeholder="Select section"
        >
          <a-select-option v-for="section in sections" :value="section.id">
            {{ section.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <p class="small">Administrators can <router-link :to="{ name: 'publications-id-sections' }">manage sections</router-link> for this publication.</p>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      loadingSections: false,
      sections: [],
      values: {
        section: [],
        title: null,
      },
    }
  },

  async mounted() {
    try {
      this.loadingSections = true

      var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/sections`)

      this.sections = data.sections
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }
    } finally {
      this.loadingSections = false
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/articles`, values)

        this.$emit('success', data)
        this.$message.success('Article has been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
