<template>
  <BaseSpinner :loading="loading">
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-10 col-xl-8">
        <h1
          id="title"
          class="editable-title mb-0"
          v-text="$store.state.article.data.title"
          contenteditable="plaintext-only"
          data-placeholder="Title"
          data-maxlength="100"
          @blur="e => $store.commit('article/field', { [e.target.id]: e.target.innerText })"
        />
        <div v-if="$store.state.article.data.language === 'en'" @click="onCapitalize" role="button" class="d-inline-flex justify-content-end">
          <small>
            <span class="text-primary">Capitalize</span>
          </small>
        </div>
        <ArticleDescription
          class="editable-description text-muted my-2"
          v-model="$store.state.article.data.description"
        />
        <ArticleEditor
          v-model="$store.state.article.data.html"
        />
      </div>
    </div>
    <div class="article-toolbar border-top fixed-bottom bg-white">
      <div class="container py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <ul class="list-inline mb-0">
              <li v-for="user in $store.state.article.data.users" class="list-inline-item">
                <a-tooltip :title="user.name" placement="top">
                  <a-avatar
                    size="large"
                    icon="user"
                    :src="user.image.resolutions.default"
                    :alt="user.name"
                  />
                </a-tooltip>
              </li>
            </ul>
          </div>
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <ArticleDrawer
                :article="$store.state.article.data"
                :visible="visible"
                @cancel="visible = false"
                @success="visible = false"
              >
                <a-button type="link" @click="visible = true">
                  <Cog6ToothIcon class="icon" />
                </a-button>
              </ArticleDrawer>
            </li>
            <li class="list-inline-item">
              <a-button type="primary" :loading="loadingUpdate" @click="onUpdate">
                Save
              </a-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </BaseSpinner>
</template>

<script>
import { Cog6ToothIcon } from '@heroicons/vue/24/outline'
import ArticleDescription from '@/components/Article/ArticleDescription'
import ArticleDrawer from '@/components/Article/ArticleDrawer'
import ArticleEditor from '@/components/Article/ArticleEditor'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    ArticleDescription,
    ArticleDrawer,
    ArticleEditor,
    BaseSpinner,
    Cog6ToothIcon,
  },

  data() {
    return {
      loading: true,
      loadingUpdate: false,
      visible: false,
    }
  },

  async created() {
    try {
      this.loading = true

      await this.$store.dispatch('article/retrieve', this.$route.params.article)
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }
    } finally {
      this.loading = false
    }
  },

  methods: {
    async onUpdate() {
      try {
        this.loadingUpdate = true

        await this.$store.dispatch('article/update')

        this.$message.success('Your changes have been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingUpdate = false
      }
    },
    onCapitalize() {
      if (this.$store.state.article.data.language !== 'en') return

      var string = this.$store.state.article.data.title
      var lowerCases = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With']

      string = string.replace(/\b\w+/g, w => w[0].toUpperCase() + w.substr(1))
      lowerCases.forEach(w => {
        string = string.replace(new RegExp(w, 'g'), w.toLowerCase())
      })

      this.$store.commit('article/field', { title: string })
    },
  },
}
</script>

<style>
.article-toolbar {
  z-index: 1;
}

[contenteditable] {
  outline: none;
}

[contenteditable]:empty:before {
  content: attr(data-placeholder);
  color: #707684;
}

.counter {
  font: 13px/1.5 'IBM Plex Mono', monospace;
  margin-top: 10px;
}

.editable-title {
  font-weight: 700;
}
</style>
