<template>
  <slot />
  <a-modal
    title="Publish Article"
    :closable="false"
    :confirmLoading="loading"
    :maskClosable="false"
    :okButtonProps="{ htmlType: 'submit' }"
    okText="Confirm"
    :visible="visible"
    @cancel="$emit('cancel')"
    @ok="onFinish"
  >
    <p>You can choose to release this article immediately or at a future date and time. Only public articles are included in API responses.</p>
    <div class="mb-4">
      <a-radio-group
        v-model:value="values.status"
        :options="[
          { label: 'Immediately', value: 'published' },
          { label: 'Schedule', value: 'scheduled' },
        ]"
      />
    </div>
    <a-date-picker
      v-model:value="values.publishedAt"
      :allowClear="false"
      :disabled="values.status === 'published'"
      :disabledDate="current => current < new Date()"
      format="YYYY-MM-DD (HH:mm)"
      placeholder="Pick a date and time"
      :showNow="false"
      :showTime="{ format: 'HH:mm' }"
      style="width: 100%;"
    />
  </a-modal>
</template>

<script>
export default {
  props: {
    article: Object,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      values: {
        publishedAt: null,
        status: 'published',
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/articles/${ this.article.id }/status`, this.values)

        if (this.values.status === 'scheduled') {
          this.$message.success('Article has been scheduled.')
        } else {
          this.$message.success('Article has been published.')
        }

        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
