<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 mb-4 mb-md-0 d-flex">
        <div class="me-3">
          <router-link class="text-dark" :to="{ name: 'publications-id-articles-id', params: { article: article.id }}">
            <img v-if="article.image.hash" class="rounded" width="80" :src="article.image.resolutions['160']">
            <img v-else class="bg-light rounded" width="80" src="@/assets/images/placeholder-16-9.png">
          </router-link>
        </div>
        <div class="w-100">
          <ArticleItemStatus
            class="float-end"
            :status="article.status"
          />
          <h2 class="h6">
            <router-link class="text-dark" :to="{ name: 'publications-id-articles-id', params: { article: article.id }}">{{ article.title }}</router-link>
          </h2>
          <a-tag v-if="article.section" class="mt-1 mb-0">{{ article.section.title }}</a-tag>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="d-flex">
          <div class="flex-fill">
            <div v-if="article.status === 'created' || article.status === 'drafted' || article.status === 'edited'">
              <p class="text-overline">Last Modified</p>
              <div>
                <a-tooltip :title="dateFormatted(article.updatedAt)" placement="bottom">
                  {{ dateFromNow(article.updatedAt) }}
                </a-tooltip>
              </div>
            </div>
            <div v-if="article.status === 'scheduled'">
              <p class="text-overline">Scheduled</p>
              <div>
                <a-tooltip :title="dateFormatted(article.scheduledAt)" placement="bottom">
                  {{ dateFromNow(article.scheduledAt) }}
                </a-tooltip>
              </div>
            </div>
            <div v-if="article.status === 'published'">
              <p class="text-overline">Published</p>
              <div>
                <a-tooltip :title="dateFormatted(article.publishedAt)" placement="bottom">
                  {{ dateFromNow(article.publishedAt) }}
                </a-tooltip>
              </div>
            </div>
          </div>
          <ul class="list-inline mb-0">
            <li v-for="user in article.users" class="list-inline-item">
              <a-tooltip :title="user.name" placement="bottom">
                <a-avatar
                  icon="user"
                  :src="user.image.resolutions.default"
                  :alt="user.name"
                />
              </a-tooltip>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-2 text-md-end">
        <a-dropdown-button size="small" :trigger="['click']">
          <a-icon type="ellipsis" />
          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item v-if="article.status !== 'published'" key="publish">
                <ArticlePublish
                  :visible="visible.publish"
                  :article="article"
                  @cancel="visible.publish = false"
                  @success="$emit('update')"
                >
                <ShareIcon class="icon me-2" />Publish
                </ArticlePublish>
              </a-menu-item>
              <a-menu-divider v-if="article.status !== 'published'" />
              <a-menu-item class="text-danger" key="delete">
                <ArticleDelete
                  :visible="visible.delete"
                  :article="article.id"
                  @cancel="visible.delete = false"
                  @success="onSuccess"
                >
                  <TrashIcon class="icon me-2" />Delete
                </ArticleDelete>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ShareIcon, TrashIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
import ArticleDelete from '@/components/Article/ArticleDelete'
import ArticleItemStatus from '@/components/Article/ArticleItemStatus'
import ArticlePublish from '@/components/Article/ArticlePublish'

export default {
  components: {
    ArticleDelete,
    ArticlePublish,
    ArticleItemStatus,
    ShareIcon,
    TrashIcon,
  },

  props: {
    article: Object,
  },

  data() {
    return {
      visible: {
        delete: false,
        publish: false,
      },
    }
  },

  methods: {
    dateFromNow(date) {
      return moment(date).fromNow()
    },
    dateFormatted(date) {
      return moment(date).format('LLLL')
    },
    onMenuClick(e) {
      this.visible[e.key] = true
    },
    onSuccess() {
      this.$emit('update')
    },
  },
}
</script>
