<template>
  <slot />
  <a-drawer
    title="Edit Article"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item>
        <div class="embed-responsive embed-responsive-16by9 rounded">
          <a-upload-dragger
            class="embed-responsive-item"
            name="file"
            accept="image/*"
            listType="picture"
            :customRequest="onUpload"
          >
            <div v-if="imageSource">
              <img class="rounded" style="width: 100%;" :src="imageSource">
            </div>
            <div v-else>
              <p class="ant-upload-drag-icon">
                <PhotoIcon class="text-muted w-25" />
              </p>
              <p class="ant-upload-text mb-0">Upload 16:9 image</p>
              <p class="ant-upload-hint">
                Click or drag image to this area to upload
              </p>
            </div>
          </a-upload-dragger>
        </div>
      </a-form-item>
      <a-form-item
        label="Language"
        name="language"
        :rules="[
          { required: true, message: 'Language is required.' },
        ]"
      >
        <a-select
          v-model:value="values.language"
          name="language"
          placeholder="Select a language"
          showSearch
        >
          <a-select-option v-for="(name, value) in $store.state.languages" :value="value">
            {{ name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Section" name="section">
        <a-select
          v-model:value="values.section"
          :loading="loadingSections"
          name="section"
          placeholder="Select section"
        >
          <a-select-option v-for="section in sections" :value="section.id">
            {{ section.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Keywords" name="keywords">
        <a-select
          v-model:value="values.keywords"
          :loading="loadingKeywords"
          mode="tags"
          name="keywords"
          placeholder="Select or enter keywords"
        >
          <a-select-option v-for="keyword in keywords" :value="keyword">
            {{ keyword }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Call-to-Action" name="action">
        <a-select
          v-model:value="values.action"
          allowClear
          :loading="loadingActions"
          name="action"
          placeholder="Select call-to-action"
        >
          <a-select-option v-for="action in actions" :value="action.id">
            {{ action.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="relations">
        <a-checkbox-group
          v-model:value="values.relations"
          name="relations"
          :options="['nofollow', 'sponsored', 'ugc']"
        />
      </a-form-item>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
import { PhotoIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    PhotoIcon,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      loadingActions: false,
      loadingKeywords: false,
      loadingSections: false,
      actions: [],
      keywords: [],
      sections: [],
      slugEnabled: false,
      uploadedImage: {
        blob: null,
        source: null,
      },
      values: {
        action: this.$store.state.article.data.action,
        keywords: this.$store.state.article.data.keywords,
        language: this.$store.state.article.data.language,
        relations: this.$store.state.article.data.relations,
        section: this.$store.state.article.data.section,
      },
    }
  },

  computed: {
    imageSource() {
      return this.uploadedImage.source || this.$store.state.article.data.image.resolutions['540']
    },
  },

  async mounted() {
    await Promise.all([
      this.listActions(),
      this.listKeywords(),
      this.listSections(),
    ])
  },

  methods: {
    async listActions() {
      try {
        this.loadingActions = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/actions`)

        this.actions = data.actions
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingActions = false
      }
    },
    async listSections() {
      try {
        this.loadingSections = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/sections`)

        this.sections = data.sections
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingSections = false
      }
    },
    async listKeywords() {
      try {
        this.loadingKeywords = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/keywords`)

        this.keywords = data.keywords
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingKeywords = false
      }
    },
    async onUpload(data) {
      try {
        var reader = new FileReader()

        reader.onload = (e) => {
          this.uploadedImage.blob = data.file
          this.uploadedImage.source = e.target.result
        }

        reader.readAsDataURL(data.file)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      return true
    },
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/articles/${ this.$store.state.article.data.id }`, values)

        if (this.uploadedImage.blob) {
          var formData = new FormData()
          formData.append('file', this.uploadedImage.blob)
          var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/articles/${ this.$store.state.article.data.id }`, formData)
        }

        this.$store.commit('article/field', { action: data.action })
        this.$store.commit('article/field', { image: data.image })
        this.$store.commit('article/field', { keywords: data.keywords })
        this.$store.commit('article/field', { language: data.language })
        this.$store.commit('article/field', { section: data.section })

        this.$message.success('Article settings have been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
