<template>
  <a-tooltip :title="statusCapitalized" placement="bottom">
    <div class="d-flex ms-2">
      <div class="me-1">
        <svg v-if="isDrafted" class="icon-small text-light-success" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
        <svg v-else class="icon-small text-light-warning" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
      </div>
      <div class="me-1">
        <svg v-if="isEdited" class="icon-small text-light-success" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
        <svg v-else-if="isDrafted" class="icon-small text-light-warning" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
        <svg v-else class="icon-small text-light" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
      </div>
      <div>
        <svg v-if="isPublished" class="icon-small text-light-success" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
        <svg v-else-if="isEdited" class="icon-small text-light-warning" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
        <svg v-else class="icon-small text-light" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="currentColor" />
        </svg>
      </div>
    </div>
  </a-tooltip>
</template>

<script>
export default {
  props: {
    status: String,
  },

  computed: {
    statusCapitalized() {
      return this.status && this.status[0].toUpperCase() + this.status.slice(1)
    },
    isDrafted() {
      var statuses = ['drafted', 'edited', 'scheduled', 'published']
      return statuses.includes(this.status)
    },
    isEdited() {
      var statuses = ['edited', 'scheduled', 'published']
      return statuses.includes(this.status)
    },
    isScheduled() {
      var statuses = ['scheduled', 'published']
      return statuses.includes(this.status)
    },
    isPublished() {
      var statuses = ['published']
      return statuses.includes(this.status)
    },
  },
}
</script>
